export const getButtonColor = (
  type: 'primary' | 'critical' | 'secondary' | 'quiet' | 'outline',
) => {
  switch (type) {
    case 'primary':
      return 'bg-primary';
    case 'critical':
      return 'bg-critical';
    case 'secondary':
      return 'bg-gray-light';
    case 'quiet':
      return 'bg-transparent';
    case 'outline':
      return 'bg-white shadow-outline-gray';
  }
};

export const getButtonDisabledColor = (
  type: 'primary' | 'critical' | 'secondary' | 'quiet' | 'outline',
) => {
  switch (type) {
    case 'primary':
      return 'bg-primary-light';
    case 'critical':
      return 'bg-critical-light';
    case 'secondary':
      return 'bg-gray-lighter';
    case 'quiet':
      return 'bg-transparent';
    case 'outline':
      return 'bg-transparent';
  }
};

export const getButtonTextColor = (
  type: 'primary' | 'critical' | 'secondary' | 'quiet' | 'outline',
) => {
  switch (type) {
    case 'primary':
    case 'critical':
      return 'text-white';
    case 'secondary':
      return '';
    case 'quiet':
      return '';
    case 'outline':
      return '';
  }
};

export const getHoverBgColor = (
  type: 'primary' | 'critical' | 'secondary' | 'quiet' | 'outline',
) => {
  switch (type) {
    case 'primary':
      return 'hover:bg-primary-dark';
    case 'critical':
      return 'hover:bg-critical-dark';
    case 'secondary':
      return 'hover:bg-gray-btn-hover';
    case 'quiet':
      return 'hover:bg-gray-light';
    case 'outline':
      return 'hover:bg-gray-light';
  }
};

export const getTextDisabledColor = (
  type: 'primary' | 'critical' | 'secondary' | 'quiet' | 'outline',
) => {
  switch (type) {
    case 'primary':
    case 'critical':
      return 'text-white';
    case 'secondary':
      return 'text-gray-dark';
    case 'quiet':
      return 'text-gray-dark';
    case 'outline':
      return 'text-gray-dark';
  }
};

export const getButtonSize = (type: 'sm' | 'md' | 'lg') => {
  switch (type) {
    case 'sm':
      return 'h-[24px] px-2 text-2xs';
    case 'md':
      return 'h-[32px] px-3 text-xs';
    case 'lg':
      return 'h-[36px] px-4 text-sm';
  }
};

export const getIconButtonSize = (type: 'sm' | 'md' | 'lg') => {
  switch (type) {
    case 'sm':
      return 'h-[24px] w-[24px] px-0 text-2xs';
    case 'md':
      return 'h-[32px] w-[32px] px-0 text-xs';
    case 'lg':
      return 'h-[36px] w-[36px] px-0 text-sm';
  }
};
